import { permissions } from '@mpk/shared/domain';

/**
 * Permission for the platform catalogs
 */
enum PlatformCatalogsPermissionsEnum {
  None = 0,
  /**
   * Platform catalogs in catalog management can be viewed
   */
  ViewPlatformCatalogs = 1,

  /**
   * Platform catalogs in catalog management can be viewed and managed
   */
  ManagePlatformCatalogs = 2 | ViewPlatformCatalogs,

  /**
   * Publish and reindex platform catalogs.
   */
  PublishCatalogs = 4,
}

export const PlatformCatalogsPermissions = permissions(PlatformCatalogsPermissionsEnum, {
  featureId: 'PlatformCatalogs',
});
